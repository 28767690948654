import React, {useRef, useEffect} from 'react';
import PropTypes from 'prop-types'; 
import { Outlet } from 'react-router-dom';
import Footer from './footer/Footer';
import Header from './Hearder';

export default function DefaultLayout ({noNavbar, noFooter}) {
//   const myRef = useRef(null);

//   useEffect(() => {
//     myRef.current?.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
//   })

   return (
        <div>
            {!noNavbar && <Header />}
                <Outlet />
            {!noFooter && <Footer />}
        </div>
    )

}

DefaultLayout.propTypes = {

    noNavbar: PropTypes.bool,
    noFooter: PropTypes.bool,
};
  
DefaultLayout.defaultProps = {
    noNavbar: false,
    noFooter: false,
};
  


