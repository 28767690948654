import React, { useState, useEffect } from "react";
import logo from "../../images/reise512.png";
import profile from "../../images/profile.svg";
import { useQuery } from "@apollo/client";
import { GET_LANGUES, GET_LANGUES_BY_CODE, GET_PARAM_BY_CODE } from "../../Queries";
import { Link, useLocation, useNavigate } from "react-router-dom";
import APP_ENV from "../../config/Env";
import "./Menu.css";
import Cookies from "universal-cookie";
import { wpBaseURL } from "../../utils/utils";
import { PopupModal } from "react-calendly";
import useAuth from "../../hooks/useAuth";
import {
  Dropdown,
  Button,
  Nav,
  Navbar,
  Offcanvas,
  Container,
  NavDropdown,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { currency$, handleAlterToChangeCurrency } from "../../utils/rxjs";
// import useCurrency from "../../hooks/useCurrency";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Menu() {

  const cookies = new Cookies();
  const { setAuth } = useAuth();
  const [position, setPosition] = useState(false);
  const { t, i18n } = useTranslation("menu");
  const { pathname } = useLocation();



  const getAuth = () => {
    let authString = localStorage.getItem("authUser");

    if (!authString && cookies.get("authUser")) {
      setAuth(cookies.get("authUser"));
      authString = cookies.get("authUser");
    } else if (authString && cookies.get("authUser")) {
      const userAuth = JSON.parse(authString)
      const cookieAuth = cookies.get("authUser")
      if (userAuth.user.email_verified_at !== cookieAuth.user.email_verified_at) {
        setAuth(cookies.get("authUser"));
        authString = cookies.get("authUser");

      }
    } else if (authString && cookies.get("authUser") === undefined) {
      setAuth(null);
      authString = null;
    }

    const userAuth = JSON.parse(authString) || null;
    return userAuth;
  };



  const auth = getAuth();

  useQuery(GET_LANGUES_BY_CODE, {
    variables: { code: i18n.resolvedLanguage },
    onCompleted: (data) => localStorage.setItem('langueId', data.langueByCode.id),
    fetchPolicy: "no-cache",
  });


  const setFixed = () => {
    if (window.scrollY >= 53) {
      setPosition(true);
    } else {
      setPosition(false);
    }
  };

  useEffect(() => {

    window.addEventListener("scroll", setFixed);
    if (cookies.get('i18nextLng')) {
      i18n.changeLanguage(cookies.get('i18nextLng'))
    }

  }, [cookies, i18n]);


  const displayCurrency = () => {
    if (pathname.includes("/projets/") || pathname.includes("/simulation-calculator/") || pathname.includes("/investment/")) {
      return true;

    } else {
      return false;
    }
  };


  return (
    <Navbar expand="xl" className={position && "fixed-top"}>
      <Container>
        <Navbar.Brand href={wpBaseURL()}>
          <img
            fetchpriority="high"
            src={logo}
            alt=""
            className="img-brand"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="offcanvasNavbar-expand" />
        <Navbar.Offcanvas
          id="offcanvasNavbar-expand"
          aria-labelledby="offcanvasNavbarLabel-expand"
          placement="start"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id="offcanvasNavbarLabel-expand" />
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className={classNames(
              "custom-navbar",
              i18n.resolvedLanguage === "en" && "mx-md-5"
            )}
            >
              <Nav.Item className="custom-home">
                <Nav.Link href={wpBaseURL()}> {t("Home")} </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <NavDropdown
                  title={t("Invest")}
                  id={`offcanvasNavbarDropdown-expand`}
                  autoClose="outside"
                >
                  <NavDropdown.Item href={wpBaseURL("lotissements")}>
                    {t("Land subdivision")}
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href={wpBaseURL("investissement-locatif")}>
                    {t("Rental investment")}
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href={wpBaseURL("promotion-immobiliere")}>
                    {t("Real estate development")}
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="/">
                    {t("See all projects")}
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav.Item>
              <Nav.Item>
                <NavDropdown
                  title={t("Buy a property")}
                  id={`offcanvasNavbarDropdown-expand`}
                  autoClose="outside"
                >
                  <NavDropdown.Item href={wpBaseURL("je-suis-une-cooperative")}>
                    {t("I am a housing co-operative")}
                  </NavDropdown.Item>
                  <NavDropdown.Item href={wpBaseURL("cooperative-reise")}>
                    {t("Join the Reise Cooperative")}
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href={wpBaseURL("je-veux-acheter-un-bien-immobilier")}>
                    {t("See all properties")}
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav.Item>
              <Nav.Item>
                <NavDropdown
                  title={t("About")}
                  id={`offcanvasNavbarDropdown-expand`}
                  autoClose="outside"
                >
                  <NavDropdown.Item href={wpBaseURL("qui-sommes-nous")}>
                    {t("Who are we ?")}
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href={wpBaseURL("comment-investir")}>
                    {t("How it works ?")}
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href={wpBaseURL("faq")}>
                    {t("FAQs")}
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav.Item>
            </Nav>
            <Nav className=" mt-2 px-sm-4 px-md-0 custom-navbar-right">
              <Nav.Item as="li">
                <CustomCalendly />
              </Nav.Item>
              <Nav.Item as="li" className={`custom-login ${displayCurrency() && "ml-n3"}`}>
                {!auth ? (
                  <div>
                    <Link to="/login" className="base-navlink login">
                      {t("Login")}
                    </Link>
                  </div>
                ) : (
                  <SplitLoggIn auth={auth?.user} />
                )}
              </Nav.Item>
              <Nav.Item className={`${displayCurrency() && "ml-n3"}`}>
                <SwitchLangue />
              </Nav.Item>
              {displayCurrency() && (
                <Nav.Item className={`${displayCurrency() && "ml-n3"}`}>
                  <SwitchCurrency />
                </Nav.Item>
              )}
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
}

function SplitLoggIn({ auth }) {

  const navigate = useNavigate();
  const cookies = new Cookies();
  const { t } = useTranslation("menu");

  const logout = () => {

    const current = new Date();
    const nextYear = new Date();
    nextYear.setFullYear(current.getFullYear() + 1);
    localStorage.removeItem("authUser");
    cookies.remove("currentId", { domain: APP_ENV.domain });
    cookies.remove("authUser", { domain: APP_ENV.domain });
    cookies.set("logoutUser", "logout", {
      domain: APP_ENV.domain,
      expires: nextYear,
    });

    // localStorage.setItem("cart", JSON.stringify([]));
    navigate("/");
  };

  return (
    <Dropdown>
      <Dropdown.Toggle
        variant="primary"
        className="btn-base-primary custom-loggind"
        id="dropdown-split-basic"
      >
        <img
          src={profile}
          alt="profil"
          className="img-fluid h-auto img-transition custom-width"
        />
      </Dropdown.Toggle>

      <Dropdown.Menu align="start" className="custom-dropdown-loggin rounded-0">
        <Dropdown.Item href={APP_ENV.baseURL}>
          {t("My account")}
        </Dropdown.Item>
        <NavDropdown.Divider />
        <Dropdown.Item href="#/logout" onClick={(e) => logout()}>
          {t("Logout")}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

function CustomCalendly() {
  const [show, setShow] = useState(false);
  const { t } = useTranslation("menu");
  return (
    <div className="pr-sm-4 pr-md-0">
      <Button
        variant="warning"
        className="btn-base-warning custom-calendly"
        onClick={(e) => setShow(true)}
      >
        {t("Request an appointment")}
      </Button>
      <PopupModal
        url="https://calendly.com/reisemarket/entretien-30min-reise"
        onModalClose={() => setShow(false)}
        open={show}
        rootElement={document.getElementById("root")}
      />
    </div>
  );
}


function SwitchLangue() {

  const { i18n } = useTranslation();
  const [langues, setLangues] = useState([]);
  const cookies = new Cookies();

  useQuery(GET_LANGUES, {
    onCompleted: (data) => {
      data.langues.map(langue => (
        setLangues(current => ({
          ...current,
          [langue.code]: { id: langue.id, nativeName: langue.code.toUpperCase() },
        }))
      ))
    }
  });


  const handleChange = (lng) => {

    i18n.changeLanguage(lng)
    localStorage.setItem('langueId', langues[lng].id)
    const current = new Date();
    const nextYear = new Date();
    nextYear.setFullYear(current.getFullYear() + 1);
    cookies.set("i18nextLng", lng, {
      domain: APP_ENV.domain,
      expires: nextYear,
    });
  }

  return (
    <Dropdown drop="down">
      <Dropdown.Toggle
        variant="link"
        id="dropdown-switch-langue"
        className="text-uppercase text-blue text-decoration-none border rounded-0">
        {i18n.resolvedLanguage}
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-switch-langue rounded-0">
        {Object.keys(langues).map((lng) => (
          <Dropdown.Item
            className="text-blue"
            key={lng}
            style={{
              fontWeight: i18n.resolvedLanguage === lng ? "bold" : "normal",
            }}
            type="submit"
            onClick={() => handleChange(lng)}
          >
            {langues[lng].nativeName}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}


function SwitchCurrency() {

  const { t } = useTranslation("menu");
  const [activeCurrency, setActiveCurrency] = useState("XOF");
  const [currencies] = useState(["EUR", "XOF"]);

  useQuery(GET_PARAM_BY_CODE, {
    variables: {
      type: "CURRENCY",
      code: activeCurrency
    },
    onCompleted: (data) => {
      currency$.next(data.parametreByCode)
      localStorage.setItem('currency', JSON.stringify(data.parametreByCode))
    },
    // fetchPolicy: "no-cache",
  });

  const handleChange = (currency) => {
    if (currency === "EUR") {
      handleAlterToChangeCurrency()
    }
    localStorage.setItem('currencyCode', currency)
    setActiveCurrency(currency)
  }


  return (
    <Dropdown
      drop="down"
      align="start">
      <Dropdown.Toggle
        variant="link"
        id="dropdown-switch-currency"
        className="text-uppercase text-blue text-decoration-none border rounded-0">
        {activeCurrency}
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-switch-langue rounded-0">
        {currencies.map((currency, i) => (
          <Dropdown.Item
            className="text-blue"
            key={i}
            style={{ fontWeight: currency === activeCurrency ? "bold" : "normal" }}
            type="submit"
            onClick={() => handleChange(currency)}
          >
            {currency}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>

  );
}