import "./footer.css";
import { Link } from "react-router-dom";
import { staticAsset, wpBaseURL } from "../../utils/utils";
import { useTranslation } from "react-i18next";
import { FaLinkedin, FaFacebookSquare, FaWhatsapp } from "react-icons/fa";

export default function Footer() {
  const { t } = useTranslation("footer");

  return (
    <footer>
      <div className="wrapper__footer bg-theme-footer">
        <div className="container">
          <div className="row g-4 d-flex justify-content-between">
            <div className="col-md-8 col-lg-5">
              <div className="widget__footer custom-first-widget__footer pr-5">
                <figure>
                  <img
                    src={staticAsset("logo-blanc.png")}
                    alt=""
                    className="logo-footer"
                  />
                </figure>
                <p>{t("description")}</p>
              </div>
              {/* contact infos */}
                <ul className="list-unstyled mb-0 mt-0 contact-information text-white">
                  <li className="my-2">
                    <b>
                      <i className="fa fa-envelope fs-6 mr-2"></i>
                    </b>
                    <span className="pt-1"> contact@reisemarket.com </span>
                  </li>
                  <li className="my-2">
                    <b>
                      <i className="fa fa-map-marker fs-5 mr-2"></i>
                    </b>
                    <span className="px-1">{t("address")}</span>
                  </li>
                </ul>
            </div>
            <div className="col-md-8 col-lg-5 flex-fill">
              <div className="widget__footer mt-md-0 mt-5">
                <h4 className="footer-title"> Menu </h4>
                <ul className="list-unstyled menu-footer mt-4">
                  <li className="nav-item">
                    <a className="nav-link" href={wpBaseURL("qui-sommes-nous")}>
                      {t("Who are we ?")}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href={wpBaseURL("comment-investir")}
                    >
                      {t("How it works")}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href={wpBaseURL("lotissements")}
                    >
                      {t("Investing in a land subdivision program in Senegal")}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href={wpBaseURL("investissement-locatif")}
                    >
                      {t("Rental Investment in Senegal")}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href={wpBaseURL("promotion-immobiliere")}>
                      {" "}
                      {t("Invest in a real estate program in Senegal")}{" "}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href={wpBaseURL("je-suis-une-cooperative")}>
                      {" "}
                      {t("I am a housing co-operative")}{" "}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href={wpBaseURL("cooperative-reise")}>
                      {" "}
                      {t("Join the Reise Cooperative")}{" "}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href={wpBaseURL("je-veux-acheter-un-bien-immobilier")}>
                      {" "}
                      {t("Buy real estate in Senegal")}{" "}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href={wpBaseURL("faq")}>
                      {" "}
                      {t("FAQs")}{" "}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href={wpBaseURL("contact")}>
                      {" "}
                      {t("Contact")}{" "}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-2 col-lg-2">
              <div className="widget__footer">
                <h4 className="footer-title"> {t("Follow us")} </h4>
                <p className="my-5">
                  <a
                    href="https://www.linkedin.com/company/reisesenegal/"
                    className="btn btn-social btn-social-o linkedin mr-3"
                    target="_blank"
                  >
                    <FaLinkedin />
                  </a>
                  <a
                    href="https://www.facebook.com/reisesenegal/"
                    className="btn btn-social btn-social-o facebook mr-3"
                    target="_blank"
                  >
                    <FaFacebookSquare />
                  </a>
                  <a
                    href="https://wa.me/221770952155"
                    className="btn btn-social btn-social-o twitter mr-1"
                    target="_blank"
                  >
                    <FaWhatsapp />
                  </a>
                </p>
                
              </div>
              <div className="widget__footer">
                <h4 className="footer-title"> {t("Useful links")} </h4>
                <ul className="list-unstyled mb-0 mt-4 menu-footer">
                  <li className="nav-item">
                    <a className="nav-link" href={wpBaseURL("condition-generale-dutilisation")}>
                      {t("Terms & Conditions")}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href={wpBaseURL("les-risques")}>
                      {t("The risks")}
                    </a>
                  </li>
                  {/* <li className="nav-item">
                    <a className="nav-link" href={wpBaseURL("les-risques")}>
                      {"Blog"}
                    </a>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg__footer-bottom-v1">
        <div className="container">
          <div className="row flex-column-reverse flex-md-row">
            <div className="col-md-6">
              <span>
                © 2022. algorixsolutions.com {t("All rights reserved")}{" "}
              </span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
